import { useEffect, useReducer } from "react";
import { initialState, reducer } from "../reducer";

const phoneMedia = window.matchMedia("(max-width: 767px)");
const tabletMedia = window.matchMedia("(max-width: 1023px)");
const laptopMedia = window.matchMedia("(max-width: 1279px)");
const desktopMedia = window.matchMedia("(min-width: 1280px)");
// const lessDesktopMedia = window.matchMedia("(max-width: 1600px)");

export const useView = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const update = () => {
      const view = {
        isPhone: phoneMedia.matches,
        isTablet: tabletMedia.matches,
        isLaptop: laptopMedia.matches,
        isDesktop: desktopMedia.matches,
      };
      dispatch({ type: "phone", payload: view.isPhone });
      dispatch({ type: "laptop", payload: view.isLaptop });
      dispatch({ type: "tablet", payload: view.isTablet });
      dispatch({ type: "desktop", payload: view.isDesktop });
    };
    try {
      phoneMedia.addEventListener("change", update);
      tabletMedia.addEventListener("change", update);
      laptopMedia.addEventListener("change", update);
      desktopMedia.addEventListener("change", update);
      // lessDesktopMedia.addEventListener("change", update);
    } catch (err) {
      try {
        phoneMedia.addListener(update);
        tabletMedia.addListener(update);
        laptopMedia.addListener(update);
        desktopMedia.addListener(update);
        // lessDesktopMedia.addListener(update);
      } catch (err2) {
        console.error(err2);
      }
    }
    update();

    return () => {
      try {
        phoneMedia.removeEventListener("change", update);
        tabletMedia.removeEventListener("change", update);
        laptopMedia.removeEventListener("change", update);
        desktopMedia.removeEventListener("change", update);
        // lessDesktopMedia.removeEventListener("change", update);
      } catch (err) {
        try {
          phoneMedia.removeListener(update);
          tabletMedia.removeListener(update);
          laptopMedia.removeListener(update);
          desktopMedia.removeListener(update);
          // lessDesktopMedia.removeListener(update);
        } catch (err2) {
          console.error(err2);
        }
      }
    };
  }, []);

  return state;
};
