import { breakpoints } from "shared/lib/breakpoints";
import { Layout } from "shared/ui/Layout";
import styled from "styled-components";
import bg from "shared/img/bg_About.jpg";

export const AboutLayout = styled(Layout)`
  background-image: linear-gradient(127.48deg,
  rgba(107, 107, 107, 0.82) 52.8%,
  rgba(255, 255, 255, 0.68) 133.33%),
  url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  max-width: 1280px;
  @media ${breakpoints.desktopView} {
    max-width: none;
  }
  text-align: center;
  margin: 0 auto;
  padding: 50px 0 120px 0;
  display: flex;
  justify-content: space-between;

  .content {
    max-width: 1280px;
  }

  .title {
    @media ${breakpoints.phoneView} {
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 28px;
    }
    @media ${breakpoints.tabletView} {
      font-size: 40px;
      line-height: 49px;
    }
    @media ${breakpoints.laptopView} {
      font-size: 50px;
      line-height: 61px;
    }
  }

  .about__text {
    margin: 40px;
    text-align: left;
    flex-wrap: wrap;
    font-family: "Inria Serif", serif;
    font-size: 25px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.78);
    text-align: justify;

    .white {
      color: #ffffff;
    }

    @media ${breakpoints.phoneView} {
      max-width: 456px;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;

    }
    @media ${breakpoints.tabletView} {
      font-size: 18px;
      line-height: 22px;
    }
    @media ${breakpoints.laptopView} {
      max-width: 600px;
      font-size: 22px;
      line-height: 27px;
    }
    @media ${breakpoints.desktopView} {
      max-width: 1280px;
      font-size: 30px;
      line-height: 40px;
    }
  }

  .about__img {
    margin: 0 auto;

    justify-content: space-between;
    @media ${breakpoints.phoneView} {
      display: block;
      max-width: 246px;
    }
    @media ${breakpoints.tabletView} {
      max-width: 300px;
    }
    @media ${breakpoints.laptopView} {
      display: flex;
      max-width: 800px;
    }
    @media ${breakpoints.desktopView} {
      max-width: 800px;
    }
  }

  .about__img img {
    display: block;
    max-width: 400px;
    max-height: 300px;
    @media ${breakpoints.phoneView} {
      margin: 20px auto;
      display: block;
      max-width: 246px;
    }
  }

  .about__img img:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: 0.4s;
  }
  
  .videoWrapper {
    margin-top: 40px;
  }
`;
