import React from "react";
import "./App.css";
import {
  About,
  Main,
  Services,
  Partners,
  Footer,
  ContactUs,
} from "./components";

function App() {
  return (
    <div className="App">
      <Main />
      <Services />
      <Partners />
      <ContactUs />
      <About />
      <Footer />
    </div>
  );
}

export default App;
