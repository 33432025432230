import React, {useEffect, useRef} from "react";
import {
  partner1Icon,
  partner2Icon,
  partner3Icon
} from "shared/img/partners";
import { PartnersLayout } from "./styled";
import { useView } from "../../processes/actions/isView";
import Aos from "aos";
import "aos/dist/aos.css"

const partnersIcons = [
  { title: "partner1", icon: partner1Icon },
  { title: "partner2", icon: partner2Icon },
  { title: "partner3", icon: partner3Icon },
];

export function Partners() {
  const ref = useRef(null);
  const { isPhone } = useView();

  const { hash } = window.location;
  if (ref.current && hash === "#partners") {
    ref.current.scrollIntoView();
  }

  useEffect(() => {
    Aos.init({ duration: 1000})
  }, [])

  return (
    <PartnersLayout ref={ref} id="partners">
      <div className="partners__logos">
        <span data-aos="fade-up" className="title">Наши партнеры</span>
        <div className="logos">
          {partnersIcons.map((item, i) => (
            <div data-aos="fade-up"
              className={`logo ${item.title} /*${
                isPhone && (i % 2 === 0 ? "leftElem" : "rightElem")
              }*/`}
              key={item.title}
            >
              <img src={item.icon} alt={`Иконка ${item.title}`} />
            </div>
          ))}
        </div>
      </div>
    </PartnersLayout>
  );
}
