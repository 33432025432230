import { breakpoints } from "shared/lib/breakpoints";
import styled from "styled-components";

export const BurgerMenu = styled.div`
  background: #050916;
  width: 50%;
  min-width: 250px;
  position: fixed;
  top: 0;
  transition: all 0.3s;
  right: ${(props) => (props.open ? 0 : "-1000px")};
  height: 100%;

  @media ${breakpoints.phoneView} {
    padding: 20px 20px 0 20px;
  }
  @media ${breakpoints.tabletView} {
    padding: 25px 25px 0 50px;
  }
  @media ${breakpoints.laptopView} {
    padding: 25px 25px 0 60px;
  }
  .closeMenu {
    display: flex;
    justify-content: end;
  }

  .title {
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;

    @media ${breakpoints.phoneView} {
      font-size: 23px;
      line-height: 28px;
    }
    @media ${breakpoints.tabletView} {
      font-size: 30px;
      line-height: 36px;
    }
  }

  .list {
    text-transform: uppercase;
    font-family: "Inria Serif";
    font-size: 17px;
    padding: 0;
    font-style: normal;
    font-weight: 300;
    @media ${breakpoints.phoneView} {
      font-size: 18px;
      line-height: 22px;
      .ant-menu-submenu-title {
        padding: 0 !important;
      }
    }
    @media ${breakpoints.tabletView} {
      font-size: 22px;
      line-height: 27px;
    }
    @media ${breakpoints.laptopView} {
      font-size: 22px;
      line-height: 27px;
    }
  }
  .list__item {
    list-style-type: none;
    margin-bottom: 20px;
  }
`;
