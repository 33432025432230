import styled from "styled-components";
import { Modal as AntModal } from "antd";
import { breakpoints } from "shared/lib/breakpoints";

export const Modal = styled(AntModal)`
  && {
    .ant-modal-body {
      overflow-x: scroll;
      @media ${breakpoints.phoneView} {
        max-height: 400px;
      }
      @media ${breakpoints.desktopView} {
        max-height: 500px;
      }
    }
  }
  @media ${breakpoints.desktopView} {
    min-width: 600px;
  }
`;
