import styled from "styled-components";
import { Layout } from "shared/ui/Layout";
import bg from "shared/img/main.png";
import { Title } from "shared/ui/Title";
import { Button } from "shared/ui/Button";
import { breakpoints } from "shared/lib/breakpoints";

export const MainLayout = styled(Layout)`
  box-sizing: border-box;
  width: 100%;
  /*transform: perspective(0px);*/
  background-image: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;

  .main__middle {
    text-align: center;
  }
`;

export const Content = styled.div`
  max-width: 1280px;
  margin: 130px auto 0;

  @media ${breakpoints.phoneView} {
    margin: 200px auto 0;
  }
  @media ${breakpoints.desktopView} {
    margin: 30px auto 0;
  }
`;

export const TitleLarge = styled(Title)`
  && {
    margin: 0;
    font-family: "Inria Serif", serif;
    font-style: normal;
    color: white;
    max-width: 710px;
    font-size: 70px;
    line-height: 85px;
    text-align: center;
    user-select: none;

    @media ${breakpoints.phoneView} {
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 28px;
      max-width: 228px;
      margin: auto;
    }
    @media ${breakpoints.tabletView} {
      font-size: 40px;
      line-height: 49px;
      max-width: 400px;
    }
    @media ${breakpoints.laptopView} {
      font-size: 60px;
      line-height: 73px;
      max-width: 600px;
    }
    @media ${breakpoints.desktopView} {
      font-size: 70px;
      line-height: 73px;
      max-width: 750px;
    }
  }
  span {
    color: #fbe74d;
  }
`;

export const SubTitle = styled(Title)`
  && {
    font-family: "Inria Serif";
    margin: 60px 0 54px;
    font-style: normal;
    color: rgba(255, 255, 255, 0.7);
    max-width: 731px;
    font-weight: 500;
    font-size: 35px;
    line-height: 43px;
    text-align: center;
    user-select: none;

    .white {
      color: #ffffff;
    }

    @media ${breakpoints.phoneView} {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      max-width: 238px;
      margin: 0 auto 30px;
    }

    @media ${breakpoints.tabletView} {
      max-width: 410px;
      font-size: 25px;
      line-height: 30px;
    }
    @media ${breakpoints.laptopView} {
      max-width: 452px;
      font-size: 30px;
      line-height: 37px;
    }
    @media ${breakpoints.desktopView} {
      padding: 24px 0;
      max-width: 700px;
      font-size: 35px;
      line-height: 40px;
    }
  }
`;

export const MainButton = styled(Button)`
  && {
    padding: 20px;
    cursor: pointer;
    width: 300px;
    height: 70px;
    border: 1px solid #fbe74d;
    box-sizing: border-box;
    font-family: "Inria Serif";
    font-style: normal;
    font-weight: 1500;
    font-size: 18px;
    line-height: 21px;
    background: transparent;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    border-radius: 5px;
    display: block;
    margin: auto;

    &:hover {
      color: black;
      background: #fbe74d;
      box-shadow: 0 0 10px #fbe74d;
      transition: 0.3s;
    }

    @media ${breakpoints.phoneView} {
      width: 240px;
    }
    @media ${breakpoints.tabletView} {
      width: 300px;
    }
  }
`;

export const MainFooter = styled.div`
  font-family: "Inria Serif";
  justify-content: space-between;
  max-width: 1280px;
  margin: 0 auto;

  @media ${breakpoints.phoneView} {
    display: inline-block;
    margin-top: 67px;
  }
  @media ${breakpoints.desktopView} {
    display: flex;
    margin: 120px auto 80px auto;
  }

  .footer__item {
    @media ${breakpoints.phoneView} {
      margin: 0 auto 30px;
      max-width: 200px;
      display: flex;
    }
    @media ${breakpoints.tabletView} {
      max-width: 300px;
    }
    @media ${breakpoints.laptopView} {
      max-width: 452px;
    }
    @media ${breakpoints.desktopView} {
    }
    .anticon {
      display: block;
    }

    .footer__item-text {
      @media ${breakpoints.phoneView} {
        width: 150px;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
      }
      @media ${breakpoints.tabletView} {
        font-size: 20px;
        line-height: 24px;
        width: 249px;
      }
      @media ${breakpoints.laptopView} {
        font-size: 30px;
        line-height: 37px;
        width: 376px;
      }
      @media ${breakpoints.desktopView} {
        font-size: 18px;
        line-height: 28px;
        width: 376px;
        margin-left: 24px;
        text-align: start;
      }
    }
  }
`;
