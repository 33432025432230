import React, { useState } from "react";
import Icon from "@ant-design/icons";
import logo from "shared/img/logo.png";
import { useView } from "processes/actions/isView";
import {
  Button, Email,
  FooterCollapse,
  FooterContent,
  FooterLayout,
  Logo,
  Tel,
} from "./styled";
import { telephoneIconWhite } from "shared/icons/icons";
import { MarginWrapper } from "shared/ui/MarginWrapper";
import { Modal } from "shared/ui/Modal";
import { Text } from "shared/ui/Text";
import { personText, politicText } from "./mockFiles";

export function Footer() {
  const { isDesktop } = useView();
  const [openPolitic, setOpenPolitic] = useState(false);
  const [openPerson, setOpenPerson] = useState(false);

  const openModal = (open) => {
    if (open === "politic") {
      setOpenPolitic(true);
    } else {
      setOpenPerson(true);
    }
  };

  const handleCancel = () => {
    setOpenPolitic(false);
    setOpenPerson(false);
  };

  return (
    <FooterLayout>
      <Modal
        title={
          (openPolitic && "Политика конфиденциальности") ||
          (openPerson && "Согласие на обработку персональных данных")
        }
        visible={openPolitic || openPerson}
        onCancel={handleCancel}
        footer={null}
      >
        <Text>
          {(openPolitic && politicText) || (openPerson && personText)}
        </Text>
      </Modal>
      {isDesktop ? (
        <FooterContent>
          <Logo>CASTELLUM</Logo>
          <div>
            <div className="footer__nav">
              <a href="#services">Услуги</a>
              <a href="#contactUs">Оставить заявку</a>
              <a href="#about">О нас</a>
              <a href="#partners">Наши партнеры</a>
            </div>
            <div className="footer__politic">
              {/*<MarginWrapper marginRight="45px">
                <Button onClick={() => openModal("politic")}>
                  Политика конфиденциальности
                </Button>
              </MarginWrapper>*/}
              <Button onClick={() => openModal("dataPerson")}>
                Согласие на обработку персональных данных
              </Button>
            </div>
          </div>
          <div>
            <Tel href="tel:+74951505635">
              <Icon className="icon" component={telephoneIconWhite} />
              +7 (495) 150-56-35
            </Tel>
            <MarginWrapper marginTop='15px'>
              <Email href="mailto:info@castellum-security.ru">
                info@castellum-security.ru
              </Email>
            </MarginWrapper>
            <MarginWrapper marginTop='15px'>
              111524, г. Москва, ул. Электродная,<br />
              д. 2, стр. 29, этаж 3, помещ. х/ком. 7
            </MarginWrapper>
          </div>

        </FooterContent>
      ) : (
        <FooterCollapse>
          {/*<Logo className="logo" src={logo} />*/}
          <Logo>CASTELLUM</Logo>
          <div className="footer__item">
            <a href="#about">О нас</a>
          </div>
          <div className="footer__item">
            <a href="#partners">Наши партнеры</a>
          </div>
          <div className="footer__politic">
            {/*<Button onClick={() => openModal("politic")}>
                Политика конфиденциальности
              </Button>*/}
            <Button onClick={() => openModal("dataPerson")}>
              Согласие на обработку персональных данных
            </Button>
          </div>
          <div className="footer__item">
            <a className="tel" href="tel:+74951505635">
              <Icon className="icon" component={telephoneIconWhite} />
              +7 (495) 150-56-35
            </a>
          </div>
          <div className="footer__item">
            <a className="tel" href="mailto:info@castellum-security.ru">
              info@castellum-security.ru
            </a>
          </div>
          <div className="footer__item">
            111524, г. Москва, ул. Электродная,<br />
            д. 2, стр. 29, этаж 3, помещ. х/ком. 7
          </div>
        </FooterCollapse>
      )}
    </FooterLayout>
  );
}
