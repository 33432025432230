import styled from "styled-components";
import { Button as AntButton } from "antd";

export const Button = styled(AntButton)``;

export const DefaultButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
`;
