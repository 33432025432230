export const initialState = {
  isPhone: false,
  isLaptop: false,
  isTablet: false,
  isDesktop: false,
};

export function reducer(state, action) {
  switch (action.type) {
    case "phone":
      return {
        ...state,
        isPhone: action.payload,
      };
    case "laptop":
      return {
        ...state,
        isLaptop: action.payload,
      };
    case "tablet":
      return {
        ...state,
        isTablet: action.payload,
      };
    case "desktop":
      return {
        ...state,
        isDesktop: action.payload,
      };
    default:
      return state;
  }
}
