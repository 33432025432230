import { breakpoints } from "shared/lib/breakpoints";
import { Layout } from "shared/ui/Layout";
import { Modal } from "shared/ui/Modal";
import styled from "styled-components";

export const ContactUsLayout = styled(Layout)`
  .ant-row {
    display: block;
  }
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background: none;
    border-color: #ff4d4f;
  }

  .form__item {
    label {
      color: white;
    }
  }
  .form__input {
    background: none;
    height: 50px;
  }
  .form__checkbox {
    color: white;
    font-size: 15px;

    a {
      color: #fbe74d;
    }
  }
  .form__button {
    padding: 20px;
    cursor: pointer;
    width: 100%;
    height: 70px;
    border: 1px solid #fbe74d;
    box-sizing: border-box;
    font-family: "Inria Serif", serif;
    font-style: normal;
    font-weight: 1500;
    font-size: 18px;
    line-height: 21px;
    background: transparent;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    border-radius: 5px;
    display: block;
    margin: 20px auto;
    &:hover {
      color: black;
      background: #fbe74d;
      box-shadow: 0 0 10px #fbe74d;
      transition: 0.3s;
    }
    &[disabled] {
      background: gray;
      pointer-events: none;
      border: 1px solid gray;
    }
    @media ${breakpoints.phoneView} {
      /* margin: 10px; */
    }
  }
  max-width: 1280px;
  margin: 0 auto;
  @media ${breakpoints.phoneView} {
    /* .form__item {
      margin: 10px;
    } */
    .form {
      padding: 0 20px;
      max-width: 500px;
      width: 100%;
      margin: 0 auto;
    }
  }
  @media ${breakpoints.desktopView} {
    .form {
      padding: 0 20px;
      max-width: none;
      width: 100%;
      margin: 0 auto;
    }
    .form__item {
      width: 48%;
      min-height: 50px;
      label {
        font-size: 20px;
        margin-top: 8px;
      }
    }
    .textarea {
      width: 100%;
    }
    .form__input {
      height: 50px;
      width: 100%;
      border-color: #ffffffb3;
      color: white;
    }

    .form__title {
      font-family: "Inria Serif", serif;
      margin-top: 20px;
      color: #fbe74d;
    }
  }
`;

export const TwoColumn = styled.div`
  @media ${breakpoints.desktopView} {
    display: flex;
    justify-content: space-between;
    max-width: 1280px;
    margin: 0 auto;
  }
`;

export const ModalSendApplication = styled(Modal)`
      @media ${breakpoints.desktopView} {
        top: 200px;
      }
  && {
    .ant-modal-title {
      @media ${breakpoints.desktopView} {
        text-align: center;
        font-size: 24px;
      }
    }
    .ant-modal-body {
      @media ${breakpoints.desktopView} {
        font-size: 22px;
      }
    }
    @media ${breakpoints.desktopView} {
      min-width: 800px;
    }
  }
`;
