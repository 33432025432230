export const breakpoints = {
  desktopView: "screen and (min-width: 1280px)",
  laptopView: "screen and (min-width: 1024px)",
  tabletView: "screen and (min-width: 768px)",
  phoneView: "screen and (min-width: 375px)",
};

// desktopView: 'screen and (min-width: 1280px)',
// laptopView: 'screen and (min-width: 1024px) and (max-width: 1279px)',
// tabletView: 'screen and (min-width: 768px) and (max-width: 1023px)',
// phoneView: 'screen and (min-width: 375px) and (max-width: 767px)',
