import React, { useCallback, useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import "./ContactUs.css";
import { Form } from "shared/ui/Form";
import { FormItem } from "shared/ui/Form/styled";
import { Button } from "shared/ui/Button";
import { ContactUsLayout, ModalSendApplication, TwoColumn } from "./styled";
import { Title } from "shared/ui/Title";
import { useForm } from "antd/lib/form/Form";
import { Input, InputTextArea } from "shared/ui/Input";
import { CheckBox } from "shared/ui/CheckBox";
import { Modal } from "shared/ui/Modal";
import { MarginWrapper } from "../../shared/ui/MarginWrapper";
import { Text } from "../../shared/ui/Text";
import { personText, politicText } from "../Footer/mockFiles";
import Aos from "aos";
import "aos/dist/aos.css";

export const ContactUs = () => {
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPerson, setOpenPerson] = useState(false);
  const [form] = useForm();

  const onFinish = useCallback(async (values) => {
    const response = await emailjs.send(
      "service_6kh2e8k",
      "template_cwsogqr",
      values,
      "6KxR30cgYDLqa3vTA"
    );
    if (response.status === 200) {
      form.resetFields();
      setOpen(true);
      setChecked(false);
    }
  }, []);

  const openModal = (open) => {
    setOpenPerson(true);
  };

  const handleCancel = () => {
    setOpenPerson(false);
  };

  const handleChackbox = (check) => {
    setChecked(check.target.checked);
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <ContactUsLayout id="contactUs">
      <Modal
        title={openPerson && "Согласие на обработку персональных данных"}
        visible={openPerson}
        onCancel={handleCancel}
        footer={null}
      >
        <Text>{openPerson && personText}</Text>
      </Modal>
      <MarginWrapper marginTop="30px" marginBottom="30px">
        <Title data-aos="fade" className="form__title">
          Оставьте заяку
        </Title>
        <Form
          className="form"
          onFinish={onFinish}
          form={form}
          initialValues={{ name: "", numberPhone: "", comment: "" }}
        >
          <TwoColumn data-aos="fade">
            <FormItem
              label="Имя"
              name="name"
              className="form__item"
              rules={[
                {
                  required: true,
                  message: "Обязательное поле",
                },
              ]}
            >
              <Input className="form__input" placeholder="Введите имя" />
            </FormItem>
            <FormItem
              label="Номер телефона"
              type="text"
              name="numberPhone"
              className="form__item"
              rules={[
                {
                  required: true,
                  message: "Обязательное поле",
                },
              ]}
            >
              <Input
                className="form__input"
                placeholder="Введите номер телефона"
              />
            </FormItem>
          </TwoColumn>
          <FormItem
            data-aos="fade"
            label="Комментарий"
            name="comment"
            className="form__item textarea"
          >
            <InputTextArea
              className="form__input"
              placeholder="Введите комментарий (необязательно)"
            />
          </FormItem>
          <FormItem data-aos="fade">
            <CheckBox
              checked={checked}
              className="form__checkbox"
              onChange={handleChackbox}
            >
              Даю{" "}
              <a onClick={() => openModal("dataPerson")}>
                согласие на обработку персональных данных
              </a>
            </CheckBox>
          </FormItem>
          <Button
            data-aos="fade"
            disabled={!checked}
            className="form__button"
            htmlType="submit"
          >
            Отправить
          </Button>
        </Form>
      </MarginWrapper>
      <ModalSendApplication
        title="Заявка отправлена"
        visible={open}
        onCancel={() => setOpen(false)}
        mask={true}
        maskClosable={true}
        footer={null}
      >
        <div style={{ textAlign: "center" }}>
          Ваша заявка успешно отправлена, мы вам перезвоним!
        </div>
      </ModalSendApplication>
    </ContactUsLayout>
  );
};

export default ContactUs;
