import { breakpoints } from "shared/lib/breakpoints";
import { Layout } from "shared/ui/Layout";
import styled from "styled-components";

export const FooterLayout = styled(Layout)`
  font-family: "Montserrat";
  @media ${breakpoints.desktopView} {
    width: 100%;
    background: #161618;
    flex-direction: inherit;
  }
`;

export const Logo = styled.div`
  width: 300px;
  font-family: "Inria Serif", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  @media ${breakpoints.phoneView} {
    font-size: 30px;
  }
  @media ${breakpoints.tabletView} {
    margin: 0 auto;
    font-size: 40px;
  }
  @media ${breakpoints.laptopView} {
    margin: 0 auto;
  }
  @media ${breakpoints.desktopView} {
    margin: 0;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  width: 1280px;
  margin: 100px auto 50px;
  font-style: normal;
  font-weight: 400;

  @media ${breakpoints.desktopView} {
    justify-content: space-between;
  }

  .footer__nav {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 25px;
    margin-right: 110px;
    a {
      &:not(:last-child) {
        margin-right: 36px;
      }
    }
    a:hover {
      color: #fbe74d;
    }
  }
  .footer__politic {
    display: flex;
    font-size: 15px;
    line-height: 166.7%;
  }
  .footer__politic:hover {
    color: #fbe74d;
  }
`;

export const Button = styled.button`
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  display: block;

  @media ${breakpoints.phoneView} {
    margin: 0 auto;
  }
  @media ${breakpoints.desktopView} {
    margin: 0;
  }
`;

export const Tel = styled.a`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  .icon {
    margin-right: 12px;
  }
  &:hover {
    color: #fbe74d;
  }
`;

export const Email = styled.a`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  .icon {
    margin-right: 12px;
  }
  &:hover {
    color: #fbe74d;
  }
`;

export const FooterCollapse = styled.div`
  max-width: 456px;
  margin: 20px auto 25px;
  align-items: center;

  .logo {
    display: block;
    width: max-content;
    margin: 0 auto 20px;

    @media ${breakpoints.phoneView} {
      width: 64px;
      height: 64px;
    }
    @media ${breakpoints.tabletView} {
      width: 90px;
      height: 90px;
    }
    @media ${breakpoints.laptopView} {
      width: 132px;
      height: 132px;
    }
  }

  .footer__item {
    width: max-content;
    margin: 20px auto;
    font-style: normal;
    font-weight: 400;
    @media ${breakpoints.phoneView} {
      font-size: 15px;
      line-height: 18px;
    }
    @media ${breakpoints.tabletView} {
      font-size: 25px;
      line-height: 30px;
    }
    @media ${breakpoints.laptopView} {
      font-size: 30px;
      line-height: 37px;
    }

    .icon {
      margin-right: 12px;
    }
  }
  .footer__politic {
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 2px !important;
    @media ${breakpoints.phoneView} {
      font-size: 10px;
      line-height: 166.7%;
    }
    @media ${breakpoints.tabletView} {
      font-size: 15px;
    }
    @media ${breakpoints.laptopView} {
      font-size: 20px;
    }
  }
`;
