import lichnaya from "shared/img/services/lichnaya_ohrana.jpg";
import object from "shared/img/services/ohrana_object.jpg";
import pult from "shared/img/services/pult.jpg";
import gruz from "shared/img/services/gruz.jpg";
import event from "shared/img/services/event.jpg";
import incass from "shared/img/services/incass.jpg";

export const servicesData = [
  {
    id: 1,
    title: "Личная охрана",
    hash: 'privateSecurity',
    text:
      "Личная охрана с “Castellum” будет организована на высшем и самом качественном уровне. " +
      "Сотрудники нашей компании обеспечат надежную защиту Вам, Вашей семье и бизнесу. " +
      "Только серьезные и проверенные сотрудники с опытом работы и заслуженными рекомендациями.",
    image: lichnaya,
    price: 10000,
  },
  {
    id: 2,
    title: "Охрана объектов",
    hash: 'objectSecurity',
    text:
      "В функции частного охранного предприятия “Castellum” входит организация охраны объектов различной специализации. " +
      "Наши возможности позволяют в каждом случае находить оптимальные эффективные решения, независимо от сложности задачи.",
    image: object,
    price: 12000,
  },
  /*{
    id: 3,
    title: "Пультовая охрана",
    text:
      "Охранное предприятие “Castellum” осуществляет монтаж охранной сигнализации как жилых, так и нежилых помещений. " +
      "Это способ защиты собственности гораздо надежнее монтажа железных дверей, усиленных стеклопакетов и организации контроля допуска.",
    image: pult,
    price: 25000,
  },
  {
    id: 4,
    title: "Инкассация",
    text:
      "Для своевременной сдачи наличности в банк или перемещения ее между структурными подразделениями большинство юридических лиц пользуются " +
        "услугами инкассаторов. Это гарантирует полную сохранность денежных средств и ответственность инкассатора в случае непредвиденных " +
        "обстоятельств. Содержать собственную службу инкассации не всегда выгодно, поэтому многие предпочитают обращаться в специализированные " +
        "организации. " +
        "Сотрудниками охранного предприятия “Castellum” оказываются услуги инкассации частной собственности на спецтранспорте (бронеавтомобиле), " +
        "осуществляется доставка ценностей, также перевозка денег.",
    image:
      incass,
    price: 50000,
  },*/
  {
    id: 5,
    title: "Сопровождение грузов",
    hash: 'cargoEscort',
    text:
      "Специалисты охранного предприятия “Castellum” имеют большой опыт по сопровождению и охране грузов и организации охраняемых перевозок, " +
      "в том числе междугородних и международных перевозок, а также перевозок в сложных условиях мегаполиса. " +
      "Сотрудничество с нами предполагает не только присутствие охранников на маршруте, но и предварительный комплекс работ, " +
      "в том числе анализ маршрута, его корректировка, моделирование возможных угроз и принятие превентивных мер по их устранению. ",
    image: gruz,
    price: 12000,
  },
  {
    id: 6,
    title: "Охрана мероприятий",
    hash: 'eventSecurity',
    text:
      "Обеспечение безопасности мероприятий требует от сотрудников внимательности, отличной физической подготовки, " +
      "опыта разрешения конфликтных ситуаций и оперативности реагирования. Дежурная смена охраны работает слаженно, " +
      "а их действия координируются начальником охраны. " +
      "Все задействованные специалисты “Castellum” перед мероприятием проходят обязательную проверку физического и психологического состояния, инструктаж. " +
      "Только такой подход может гарантировать надежную охрану порядка в местах проведения спортивных, музыкальных, развлекательных событий, различных массовых праздников.",
    image: event,
    price: 30000,
  },
];

export const MENU = [
  { title: "услуги", hash: "sevices", sub: servicesData },
  { title: "наши партнеры", hash: "partners" },
  { title: "CASTELLUM", logo: true },
  { title: "о нас", hash: "about" },
];

export const MenuMobile = [
  { title: "CASTELLUM", logo: true },
  { title: "Наши услуги", hash: "sevices", sub: servicesData },
  { title: "наши партнеры", hash: "partners" },
  { title: "о нас", hash: "about" },
];