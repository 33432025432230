import { useView } from "processes/actions/isView";
import React, {useEffect, useRef, useState} from "react";
import Aos from "aos";
import "aos/dist/aos.css"
import { Divider } from "shared/ui/Divider";
import { servicesData } from "shared/lib/mockData";
import { ServicesLayout } from "./styled";

export function Services() {
  const { isTablet } = useView();
  const ref = useRef(null);

  const { hash } = window.location;
  if (ref.current && hash.length) {
    ref.current.scrollIntoView();
  }

  const [services] = useState(servicesData);

  useEffect(() => {
    Aos.init({ duration: 1000})
  }, [])

  return (
    <ServicesLayout ref={ref} id="services">
      <div className="container">
        <div data-aos="fade-up" className="services__title">Наши услуги</div>
        <div className="services__cards">
          {services.map((service) => (
            <div id={service.hash} data-aos="fade-up" className="service__card" key={service.id}>
              <div className="card__img">
                <img alt="" src={service.image} />
              </div>
              <div className="card__title">{service.title}</div>
              <div className="card__desc">{service.text}</div>
              {/*<div className="card__price">{service.price} руб</div>*/}
              {isTablet && <Divider className="service__divider" />}
            </div>
          ))}
        </div>
      </div>
    </ServicesLayout>
  );
}
