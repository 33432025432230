import React, {useEffect, useRef} from "react";
import Icon from "@ant-design/icons";
import { Header } from "../Header";
import {
  Content,
  MainButton,
  MainFooter,
  MainLayout,
  SubTitle,
  TitleLarge,
} from "./styled";
import {
  calendarIcon,
  employeeIcon,
  odometerIcon,
  timeIcon,
} from "shared/icons/icons";
import { useView } from "processes/actions/isView";
import Aos from "aos"
import "aos/dist/aos.css"

export function Main() {
  const { isDesktop } = useView();
  const ref = useRef(null);

  const { hash } = window.location;
  if (ref.current && hash === "#main") {
    ref.current.scrollIntoView();
  }

  useEffect(() => {
    Aos.init({ duration: 1600})
  }, [])

  const handleClick = () => {};
  return (
    <MainLayout ref={ref} id="main">
      <Header />
      <Content>
        <div data-aos="fade" className="main__middle">
          <TitleLarge level={1}>
            ВСЕ ВИДЫ <span>ОХРАННЫХ УСЛУГ</span>
          </TitleLarge>
          <SubTitle level={2}>
            <b className="white">Доверяйте</b> безопасность Вашей жизни
            <br /> и бизнеса <b className="white">профессионалам</b>
          </SubTitle>
          <MainButton onClick={handleClick}>
            <a href='#contactUs'>оставить заявку</a>
          </MainButton>
        </div>
      </Content>
      <MainFooter data-aos="fade-up">
        {!isDesktop ? (
          <>
            <div className="footer__item">
              <Icon component={timeIcon} />{" "}
              <div className="footer__item-text">
                Круглосуточная поддержка 24/7
              </div>{" "}
            </div>
            <div className="footer__item">
              <Icon component={employeeIcon} />{" "}
              <div className="footer__item-text">
                Опытные сотрудники охраны 6-го разряда
              </div>{" "}
            </div>
            <div className="footer__item">
              <Icon component={calendarIcon} />{" "}
              <div className="footer__item-text">
                Быстрое реагирование на сигнал
              </div>{" "}
            </div>
          </>
        ) : (
          <>
            <div className="footer__item">
              <Icon component={odometerIcon} />{" "}
              <div className="footer__item-text">
                Круглосуточная поддержка 24/7
              </div>{" "}
            </div>
            <div className="footer__item">
              <Icon component={calendarIcon} />{" "}
              <div className="footer__item-text">
                Оперативность реагирования на сигнал
              </div>{" "}
            </div>
            <div className="footer__item">
              <Icon component={employeeIcon} />{" "}
              <div className="footer__item-text">
                Опытные сотрудники охраны 6-го разряда
              </div>{" "}
            </div>
          </>
        )}
      </MainFooter>
    </MainLayout>
  );
}
