import styled from "styled-components";
import Layout from "antd/lib/layout/layout";
import { breakpoints } from "shared/lib/breakpoints";
import bg from "shared/img/biznes-rukopozhatie-sdelka-2789.jpg";

export const PartnersLayout = styled(Layout)`
  width: 100vw;
  max-height: max-content;
  margin: auto;
  padding: 60px 0;
  display: flex;
  justify-content: center;
  background: #1c1c1c;

  @media ${breakpoints.phoneView} {
    background-image: linear-gradient(
        127.48deg,
        rgba(107, 107, 107, 0.71) 52.8%,
        rgba(255, 255, 255, 0.3) 133.33%
      ),
      url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
  }

  .partners__logos {
    max-width: 1280px;
    margin: 0 auto;
    text-align: center;
  }

  .title {
    @media ${breakpoints.phoneView} {
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 28px;
    }
    @media ${breakpoints.tabletView} {
      font-size: 40px;
      line-height: 49px;
    }
    @media ${breakpoints.laptopView} {
      font-size: 50px;
      line-height: 61px;
    }
  }

  .logos {
    @media ${breakpoints.phoneView} {
      //display: flex;
      flex-wrap: wrap;
      margin: 30px auto;
      justify-content: space-between;
      align-content: center;
      text-align: center;
      //position: relative;
      max-width: 456px;
    }
    @media ${breakpoints.laptopView} {
      display: flex;
      justify-content: space-around;
      //flex-wrap: nowrap;
      //max-width: none;
      width: 800px;
      flex-wrap: wrap;
      max-width: none;
    }
    @media ${breakpoints.desktopView} {
      width: 1280px;
    }
  }

  .logo {
    /*width: 100%;*/
    /* margin: 0 auto; */

    @media ${breakpoints.phoneView} {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  .logo img {
    display: inline-block;
  }

  .logo img:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: 0.4s;
  }

  .leftElem {
    @media ${breakpoints.phoneView} {
      justify-content: flex-start;
    }
  }

  .rightElem {
    @media ${breakpoints.phoneView} {
      justify-content: flex-end;
    }
  }

  .partner1 img {
    @media ${breakpoints.phoneView} {
      max-width: 200px;
      margin: auto;
    }
    @media ${breakpoints.laptopView} {
      max-width: 200px;
    }
  }
  .partner2 img {
    @media ${breakpoints.phoneView} {
      max-width: 360px;
      height: 90px;
      margin: auto;
    }
    @media ${breakpoints.laptopView} {
      max-width: 400px;
      height: 100px;
    }
  }
  .partner3 img {
    @media ${breakpoints.phoneView} {
      max-width: 360px;
      margin: auto;
    }
    @media ${breakpoints.laptopView} {
      max-width: 400px;
    }
  }
`;
