import React, { useEffect, useState } from "react";
import Icon from "@ant-design/icons";
import {
  BlockHeader,
  BlockTel,
  CollapseHeader,
  Logo,
  MenuHeader,
  MenuItem,
  NavContent,
  NavLi,
  Tel,
} from "./styled";
import {
  closeIconBig,
  closeIconMedium,
  closeIconSmall,
  telephoneIcon,
} from "shared/icons/icons";
import { useView } from "processes/actions/isView";
import { MarginWrapper } from "../../shared/ui/MarginWrapper";
import { BurgerMenu } from "shared/ui/BurgerMenu";
import { DefaultButton } from "shared/ui/Button/styled";
import Aos from "aos";
import "aos/dist/aos.css";
import { SubMenu } from "shared/ui/Menu";
import { MENU, MenuMobile } from "shared/lib/mockData";

export function Header() {
  const [open, setOpen] = useState(false);

  const { isDesktop, isTablet, isPhone } = useView();

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const handleOpen = (open) => setOpen(open);
  return isDesktop ? (
    <BlockHeader data-aos="fade">
      <div className="content">
        <NavContent>
          <MenuHeader mode="horizontal" triggerSubMenuAction="click">
            {MENU.map((item, index) => (
              <>
                {item.logo ? (
                  <MarginWrapper marginLeft="12px" marginRight="110px">
                    <div className="title">CASTELLUM</div>
                  </MarginWrapper>
                ) : item.sub ? (
                  <SubMenu
                    title={
                      <MarginWrapper
                        className="servicesTitle"
                        marginTop="8px"
                        marginRight="88px"
                      >
                        {item.title}
                      </MarginWrapper>
                    }
                  >
                    {item.sub &&
                      item.sub.map((el, i) => (
                        <MenuItem>
                          <NavLi href={`#${el.hash}`}>{el.title}</NavLi>
                        </MenuItem>
                      ))}
                  </SubMenu>
                ) : (
                  <MenuItem className="menuItem">
                    <NavLi href={`#${item.hash}`}>{item.title}</NavLi>
                  </MenuItem>
                )}
              </>
            ))}
          </MenuHeader>
          <BlockTel>
            <MarginWrapper marginRight="10px">
              <Icon className="icon" component={telephoneIcon} />
            </MarginWrapper>
            <Tel className="tel" href="tel:+74951505635">
              +7 (495) 150-56-35
            </Tel>
          </BlockTel>
        </NavContent>
      </div>
    </BlockHeader>
  ) : (
    <CollapseHeader>
      <Logo>CASTELLUM</Logo>
      <DefaultButton onClick={() => handleOpen(true)} className="iconBurger">
        <div />
        <div />
        <div />
      </DefaultButton>
      <BurgerMenu open={open}>
        <div className="closeMenu">
          <DefaultButton onClick={() => handleOpen(false)}>
            {isPhone ? (
              <Icon component={closeIconSmall} />
            ) : isTablet ? (
              <Icon component={closeIconMedium} />
            ) : (
              <Icon component={closeIconBig} />
            )}
          </DefaultButton>
        </div>
          <MenuHeader mode="inline" triggerSubMenuAction="click">
            {MenuMobile.map((item, index) => (
              <>
                {item.logo ? (
                    <div className="title">CASTELLUM</div>
                ) : item.sub ? (
                  <SubMenu
                    title={
                      <MarginWrapper
                        className="list"
                      >
                        {item.title}
                      </MarginWrapper>
                    }
                  >
                    {item.sub &&
                      item.sub.map((el, i) => (
                        <MenuItem className="list">
                          <NavLi href={`#${el.hash}`}>{el.title}</NavLi>
                        </MenuItem>
                      ))}
                  </SubMenu>
                ) : (
                  <MenuItem className="menuItem list">
                    <NavLi href={`#${item.hash}`}>{item.title}</NavLi>
                  </MenuItem>
                )}
              </>
            ))}
          </MenuHeader>
      </BurgerMenu>
    </CollapseHeader>
  );
}
