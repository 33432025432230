import styled from "styled-components";
import { Form as AntForm } from "antd";
import { Input } from "../Input";

export const Form = styled(AntForm)``;

export const FormItem = styled(Form.Item)``;

export const FormInput = styled(Input)``;

export const FormInputTextArea = styled(Input.TextArea)``;