import styled from "styled-components";
import { Layout } from "shared/ui/Layout";
import { breakpoints } from "shared/lib/breakpoints";

export const ServicesLayout = styled(Layout)`
  background: rgb(0, 0, 0);
  background-size: cover;

  .container {
    max-width: 1280px;
    margin: 60px auto;
  }

  .services__title {
    width: 50%;
    text-align: center;
    font-family: "Inria Serif", serif;
    margin: auto;
    cursor: default;
    font-size: 70px;
    line-height: 85px;
    text-transform: uppercase;

    @media ${breakpoints.phoneView} {
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 28px;
    }
    @media ${breakpoints.tabletView} {
      font-size: 40px;
      line-height: 49px;
    }
    @media ${breakpoints.laptopView} {
      font-size: 50px;
      line-height: 61px;
    }
    @media ${breakpoints.desktopView} {
      font-weight: 300;
      font-size: 70px;
      line-height: 85px;
    }
  }
  .services__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .service__card {
    margin-top: 63px;
    min-width: 428px; 
    
    border-radius: 5px;
    @media ${breakpoints.phoneView} {
      min-width: 370px;
    }

    @media ${breakpoints.laptopView} {
      background: rgba(255, 255, 255, 0.1);
      box-sizing: border-box;
      &:hover {
        background: rgba(255, 255, 255, 0.15);
        /*box-shadow: 0 0 10px rgba(255, 255, 255, 0.46);*/
        transition: 0.4s;
      }
    }
  }

  .card__desc {
    width: 70%;
    margin: 10px auto;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: justify;
    letter-spacing: -0.015em;
    color: rgba(255, 255, 255, 0.7);
    cursor: default;
    @media ${breakpoints.phoneView} {
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
    }
    @media ${breakpoints.tabletView} {
      font-size: 20px;
      line-height: 24px;
      padding: 20px
    }
    @media ${breakpoints.laptopView} {
      font-size: 18px;
      line-height: 22px;
      max-width: 368px;
      padding: 5px;
      width: 100%;
    }
    @media ${breakpoints.desktopView} {
      font-weight: 300;
      font-size: 24px;
      line-height: 28px;
      padding: 5px
    }
  }

  /*.service__card:hover {
    color: black;
    background: rgba(255, 255, 255, 0.7);
    cursor: default;
    transition: 0.6s;
    transform: scale(1.2);
  }*/

  .card__img {
    width: 100%;
    /* height: 160px; */
    overflow: hidden;
    @media ${breakpoints.phoneView} {
      max-width: 260px;
      margin: 0 auto;
    }
    @media ${breakpoints.tabletView} {
      max-width: 368px;
    }
    @media ${breakpoints.laptopView} {
      max-width: 428px;
      max-height: 200px;
    }
  }

  .card__img img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    @media ${breakpoints.laptopView} {
      min-height: 200px;
    }
  }

  .card__title {
    width: 100%;
    font-family: "Inria Serif";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    margin: auto;
    margin-top: 25px;
    cursor: default;

    @media ${breakpoints.phoneView} {
      font-size: 18px;
      line-height: 22px;
    }
    @media ${breakpoints.tabletView} {
      font-weight: 500;
      font-size: 30px;
      line-height: 37px;
    }
    @media ${breakpoints.desktopView} {
      font-weight: 300;
      font-size: 30px;
      line-height: 37px;
    }
  }

  .card__price {
    margin-top: 18px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }

  .service__divider {
    margin: 30px 0;
    @media ${breakpoints.phoneView} {
      width: 100%;
    }
    @media ${breakpoints.tabletView} {
      width: 500px;
      margin: 30px auto;
    }
  }
`;
