import { breakpoints } from "shared/lib/breakpoints";
import { Menu } from "shared/ui/Menu";
import styled from "styled-components";

export const BlockHeader = styled.div`
  width: 100%;
  min-height: 184px;
  background: linear-gradient(#000000, rgba(117, 190, 218, 0));
  /*background-color: #1c1c1c;*/
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 19px;
  text-transform: uppercase;
  .content {
    max-width: 1280px;
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
  }
`;

export const NavContent = styled.div`
  margin: 30px 0;
  display: flex;
  text-align: center;
`;

export const Logo = styled.div`
  font-family: "Inria Serif";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  margin: 5px auto;
  @media ${breakpoints.tabletView} {
    font-size: 45px;
    line-height: 50px;
    margin: 2px auto;
  }
  @media ${breakpoints.laptopView} {
    font-size: 60px;
    line-height: 73px;
    margin: 0px auto;
  }
`;

export const NavLi = styled.a`
  font-family: "Inria Serif";
  text-decoration: none;
  color: white !important;
  .icon {
    margin-right: 15px;
  }
  /* @media ${breakpoints.phoneView} { */
    &:hover {
      color: #fbe74d !important;
    }
  /* } */
`;

export const Tel = styled.a`
  text-decoration: none;
  color: inherit;
  display: flex;
  &:hover {
    color: #fbe74d;
  }

  .icon {
    margin-right: 15px;
  }
`;

export const CollapseHeader = styled.div`
  width: 100%;
  position: fixed;
  z-index: 5;
  min-height: 200px;
  padding: 30px 20px 30px 20px;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(#000000, rgba(117, 190, 218, 0));

  .logo {
    @media ${breakpoints.phoneView} {
      width: 64px;
      height: 64px;
    }
    @media ${breakpoints.tabletView} {
      width: 90px;
      height: 90px;
    }
    @media ${breakpoints.laptopView} {
      width: 110px;
      height: 110px;
    }
  }

  .iconBurger {
    display: grid;
    margin-top: 15px;
    @media ${breakpoints.phoneView} {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 25px;
      right: 10px;
    }
    @media ${breakpoints.tabletView} {
      width: 40px;
      height: 40px;
    }
    div {
      @media ${breakpoints.phoneView} {
        width: 24px;
        height: 3px;
      }
      @media ${breakpoints.tabletView} {
        width: 40px;
        height: 4px;
      }
      background-color: white;
      border-radius: 10px;
      transition: all 0.3s linear;
      transform-origin: 1px;
    }
  }
`;

export const BurgerMenuMain = styled.div`
  position: fixed;
`;

export const MenuHeader = styled(Menu)`
  .menuItem {
    @media ${breakpoints.desktopView} {
      margin-top: 8px !important;
      &:not(:last-child) {
        margin-right: 80px;
      }
    }
  }
`;

export const MenuItem = styled(Menu.Item)`
  order: inherit !important;
  background-color: none !important;
  &:hover {
    color: #fbe74d !important;
  }
  @media ${breakpoints.phoneView} {
    padding: 0 !important;
  }
  @media ${breakpoints.desktopView} {
    padding-left: 24px !important;
    padding-right: 20px !important;
  }
`;

export const BlockTel = styled.div`
  margin-top: 20px;
  display: flex;
`;
