import styled from "styled-components";
import { Menu as AntMenu } from "antd";

export const Menu = styled(AntMenu)`
  background: none;
  border-bottom: none;
  color: white;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  .servicesTitle {
    font-family: "Inria Serif";
    font-size: 17px;
  }
`;

export const SubMenu = styled(AntMenu.SubMenu)`
  background: none;
`;
