import shell from "./shell.png";
import gazprom from "./gazprom.png";
import tinkoff from "./tinkoff.png";
import sber from "./sber.png";
import partner1 from './partner1.png'
import partner2 from './flagman2.png'
import partner3 from './partner3.png'

export const shellIcon = shell;
export const gazpromIcon = gazprom;
export const tinkoffIcon = tinkoff;
export const sberIcon = sber;
export const partner1Icon = partner1;
export const partner2Icon = partner2;
export const partner3Icon = partner3
