import React, {useEffect, useRef} from "react";
import license1 from "../../shared/img/license/license_1.JPG";
import license2 from "../../shared/img/license/license_2.JPG";
import license3 from "../../shared/img/license/license_3.JPG";
import { AboutLayout } from "./styled";
import Aos from "aos";
import "aos/dist/aos.css";
import {useView} from "../../processes/actions/isView";

export function About() {
  const ref = useRef(null);
  const { isLaptop ,isTablet, isPhone } = useView();

  const { hash } = window.location;
  if (ref.current && hash === "#about") {
    ref.current.scrollIntoView();
  }

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <AboutLayout ref={ref} id="about">
      <div className="content">
        <span data-aos="fade" className="title">о нас</span>
        <div data-aos="fade" className="about__text">
          <b>
            <b className='white'>
              «Castellum»
            </b> с момента основания главной целью своей деятельности ставит <b className='white'>обеспечение безопасности Заказчика,
            его репутации и имущества,</b> используя для этого полный спектр технических и физических средств, оставаясь в рамках законодательства РФ.
            <b className='white'> Мы гарантируем</b> оказание действительно качественных охранных услуг, предлагая при этом <b className='white'>антикризисные цены,</b> несколько меньшие средних по рынку,
            при этом полностью оставаясь верными своему принципу:<br /> <b className='white'>интересы Клиента превыше всего.</b>
          </b>
        </div>
        <div className="about__img">
          <div data-aos="fade">
            <a href={license1} target="_blank">
              <img alt="lic1" src={license1} />
            </a>
          </div>
          <div data-aos="fade">
            <a href={license2} target="_blank">
              <img alt="lic1" src={license2} />
            </a>
          </div>
          <div data-aos="fade">
            <a href={license3} target="_blank">
              <img alt="lic1" src={license3} />
            </a>
          </div>
        </div>
        <div className='videoWrapper' data-aos="fade">
          <iframe
              width={
                  isPhone ?  '370' :
                  isTablet ? '418' :
                  isLaptop ? '600' :
                             '800'
          }
              height={
                  isPhone ?  '200' :
                  isTablet ? '230' :
                  isLaptop ? '350' :
                             '450'}

                  src="https://www.youtube.com/embed/xAhcuCNlYYE"
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen/>
        </div>
      </div>
    </AboutLayout>
  );
}
